
import { Component, Vue } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { Getter, State } from 'vuex-class';
import LibraryApi from '@/module/api/LibraryApi';
import Category, { ENGINNER, GENERAL } from '@/model/library/Category';
import LibraryInfo from '@/model/library/LibraryInfo';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate'
]);
@Component
export default class Library extends Vue {
    @Getter('isAdminGroup')isAdminGroup!: boolean;
    category:Category=new Category();
    libraryInfoList:LibraryInfo[]=[];
    searchKeyWord:string = '';
    searchLibraryInfoList:LibraryInfo[]=[];

    beforeRouteEnter(to: Route, from: Route, next: any): void {
      next((vm: Library) => vm.initialize(to));
    }
    beforeRouteUpdate(to: Route, from: Route, next: any): void {
      this.initialize(to);
      next();
    }
    initialize(to: Route): void {
      this.libraryInfoList = [];
      if (to.params.class === 'tec') {
        this.category = ENGINNER;
      }
      if (to.params.class === 'general') {
        this.category = GENERAL;
      }
      LibraryApi.findByCategory(this.category.value).then((res) => {
        this.libraryInfoList = res.data;
        this.searchLibraryInfoList = this.libraryInfoList;
      });
    }
    search():void {
      this.searchLibraryInfoList = this.libraryInfoList.filter((data) => this.isShowTarget(data));
    }
    isShowTarget(info:LibraryInfo):boolean {
      let rtnVal = false;
      if (info.fileInfo.name.value.indexOf(this.searchKeyWord) > -1) {
        rtnVal = true;
      }
      if (info.overview.value.indexOf(this.searchKeyWord) > -1) {
        rtnVal = true;
      }
      return rtnVal;
    }
}
