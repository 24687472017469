export default class Category {
  value: string;
  dispString:string;

  constructor(value?: string, dispString?:string) {
    this.value = (value === undefined ? '' : value);
    this.dispString = (dispString === undefined ? '' : dispString);
  }

  getDispString():void {
    if (this.value === 'general') {
      this.dispString = '総務・経理';
    }
    if (this.value === 'enginner') {
      this.dispString = '技術';
    }
  }
}
export const GENERAL = new Category('general', '総務・経理');
export const ENGINNER = new Category('enginner', '技術');
