import { AxiosPromise } from 'axios';
import LibraryInfo from '@/model/library/LibraryInfo';
import axios from '../AxiosBase';

export default {
  findByCategory(category:string):AxiosPromise<any> {
    return axios.get(`library/byCategory/${category}`);
  },
  findById(fileId:string):AxiosPromise<any> {
    return axios.get(`admin/library/byId/${fileId}`);
  },
  save(data:FormData):AxiosPromise<any> {
    return axios.post('admin/library/save', data);
  },
  delete(libraryInfo:LibraryInfo):AxiosPromise<any> {
    return axios.post('admin/library/delete', libraryInfo);
  }
};
